<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td>
                                入库单编号：
                            </td>
                            <td>
                                <el-input  v-model="searchItem.inNum" size="small" placeholder="请输入入库编号"></el-input>
                            </td>
                            <td style="width: 80px">
                                入库仓库：
                            </td>
                            <td>
                                <el-select v-model="searchItem.warehouseType" size="small"
                                           placeholder="请选择入库仓库">
                                    <el-option
                                            v-for="item in depotTypes"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </td>
                            <td style="width: 80px">
                                入库时间：
                            </td>
                            <td >
                                <el-date-picker
                                        v-model="searchItem.startDate"
                                        type="date"
                                        size="small"
                                        placeholder="选择日期时间"
                                        style="width: 46.5%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                                至
                                <el-date-picker
                                        v-model="searchItem.endDate"
                                        type="date" placeholder="选择日期时间"
                                        size="small"
                                        style="width: 47%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </td>
                            <td style="text-align: center">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" size="small" @click="reset">重置
                                </el-button>
                                <el-button type="primary" icon="el-icon-circle-plus" size="small"
                                           v-if="hasAuthority('in_depot_update')" @click="addObject">
                                    增加
                                </el-button>
                                <el-button type="primary" icon="el-icon-download" size="small" @click="exportExcel">导出excel文件</el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" width="50" label="排序" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="inNum" width="180" label="入库单编号" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="buyNum" width="180" label="采购单号" align="center"
                            show-overflow-tooltip></el-table-column>
                            <el-table-column prop="afterSalesNum" width="180" label="售后申请编号" align="center"
                            show-overflow-tooltip></el-table-column>
                            <el-table-column prop="createUserName" width="90" label="操作人" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="inType" width="130" label="入库类型" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope='scope'>
                                    <span v-if="scope.row.inType == 0">主物料入库</span>
                                    <span v-else-if="scope.row.inType ==1">研发物料入库</span>
                                    <span v-else-if="scope.row.inType ==2">外协入库</span>
                                    <span v-else-if="scope.row.inType ==3">售后入库</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="inTime" width="160" label="入库时间" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope='scope'>
                                    <span>{{changeDateFormat(scope.row.inTime)}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="warehouseType" width="160" label="入库仓库" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope='scope'>
                                    <span v-if="scope.row.warehouseType == 0">主物料仓库</span>
                                    <span v-else-if="scope.row.warehouseType ==1">研发物料仓库</span>
                                    <span v-else-if="scope.row.warehouseType ==2">外协仓库</span>
                                    <span v-else-if="scope.row.warehouseType ==3">售后仓库</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="remark" width="200" label="备注" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="isInning" label="状态" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope='scope'>
                                    <span v-if="scope.row.isInning == 0" style="color: red">未入库</span>
                                    <span v-else-if="scope.row.isInning ==1" style="color: green;">已入库</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="id" width="150" label="操作" align="center">
                                <template slot-scope="scope">
                                    <div class="opertion">
                                        <i class="iconfont iconbianji" style="margin-right: 10%"
                                           v-if="hasAuthority('in_depot_update') && scope.row.isInning == 0"
                                           title="编辑" @click="editInDepot(scope.row.id)"></i>
                                        <i class="iconfont iconxiangqing" title="详情"
                                           @click="showInDepot(scope.row.id)"></i>
                                        <i class="iconfont icontongguo" style="margin-left: 10%" title="入库"
                                           v-if="hasAuthority('in_depot_sure') && scope.row.isInning == 0"
                                           @click="submitInDepot(scope.row.id)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="dialog-edit">
            <el-dialog
                    :title="editTitle"
                    :visible.sync="editDialogVisible"
                    width="50%"
                    :close-on-click-modal="false"
                    center>
                <div class="basic-info">
                    <el-form :model="inDepotForm" :rules="inDepotRules" ref="inDepotForm" label-width="100px"
                             class="demo-ruleForm">
                        <el-form-item size="small" label="入库类型" prop="inType">
                            <el-select
                                    size="small"
                                    style="width: 100%"
                                    v-model="inDepotForm.inType"
                                    placeholder="请选择入库类型"
                                    @change="checkType"
                            >
                                <el-option
                                        v-for="item in depotTypes"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-if="inDepotForm.inType == 3" size="small" :label="labelName" prop="buyId">
                            <el-select
                                    size="small"
                                    style="width: 100%"
                                    filterable
                                    v-model="inDepotForm.afterSalesId"
                                    :placeholder="orderName"
                                    @change="getAfterDetail"
                            >
                                <el-option
                                        v-for="(item) in afterList"
                                        :key="item.id"
                                        :label="item.applyNum"
                                        :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-else size="small" :label="labelName" prop="buyId">
                            <el-select
                                    size="small"
                                    style="width: 100%"
                                    filterable
                                    v-model="inDepotForm.buyId"
                                    :placeholder="orderName"
                                    @change="getOrderDetail"
                            >
                                <el-option
                                        v-for="(item) in purchaseList"
                                        :key="item.id"
                                        :label="item.buyNum"
                                        :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item size="small" label="入库仓库" prop="warehouseType">
                            <el-select
                                    size="small"
                                    style="width: 100%"
                                    :disabled="isDisabled"
                                    v-model="inDepotForm.warehouseType"
                                    placeholder="请选择入库仓库"
                                    @change="getMaterialList"
                            >
                                <el-option
                                        v-for="item in depotList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item size="small" label="备注" prop="remark">
                            <el-input v-model="inDepotForm.remark" type="textarea" rows="3" size="small"
                                      placeholder="备注"></el-input>
                        </el-form-item>
                        <el-table
                                :data="tableList"
                                border
                                ref="multipleTable"
                                style="width: 100%;margin-bottom: 10px"
                                max-height="340"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}"
                                @selection-change="handleSelectionChange"
                        >
                            <el-table-column type="selection" width="40" align="center"></el-table-column>
                            <el-table-column prop="materialCode" label="物料编码" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="materialName" label="产品名称" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="model" label="型号" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column v-if="inDepotForm.inType == 3 && type == 0" prop="count"
                                             label="出库数量" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.outCount>0">{{scope.row.outCount}}</span>
                                    <span v-else>0</span>
                                </template>
                            </el-table-column>
                            <el-table-column v-else-if="inDepotForm.inType != 3" prop="count" label="采购数量" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.count>0">{{scope.row.count}}</span>
                                    <span v-else>0</span>
                                </template>
                            </el-table-column>
                            <el-table-column v-if="inDepotForm.inType != 3" prop="inCounted" label="已入库数量" align="center"
                                             show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="inCount" label="入库数量" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-input type="tel" size="small"
                                              v-model.number="scope.row.inCount" v-if="scope.row.checked == false"
                                              onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                              placeholder="请输入数量" disabled></el-input>
                                    <el-input type="tel" size="small"
                                              v-model.number="scope.row.inCount" v-else
                                              onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                              placeholder="请输入数量"></el-input>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                        <el-button @click="cancel('inDepotForm')">取 消</el-button>
                        <el-button type="primary" @click="submit('inDepotForm')" :loading="isLoad">确 定</el-button>
                    </span>
            </el-dialog>
        </div>
        <div class="dialog-info">
            <el-dialog
                    :title="showTitle"
                    :visible.sync="infoDialogVisible"
                    width="50%"
                    :close-on-click-modal="false"
                    center>
                <div class="infomation">
                    <table width="100%">
                        <tr>
                            <td width="100px" style="text-align: left">入库单编号:</td>
                            <td style="text-align: left">{{inDepotDisplay.inNum}}</td>
                            <td width="80px" style="text-align: left">入库类型:</td>
                            <td style="text-align: left">
                                {{inDepotDisplay.inType == 0?"主物料入库":
                                (inDepotDisplay.inType == 1?"研发物料入库" :
                                inDepotDisplay.inType == 2?"外协入库":'售后入库')}}
                            </td>
                            <td width="80px" style="text-align: left">入库仓库:</td>
                            <td style="text-align: left">
                                {{inDepotDisplay.warehouseType == 0?"主物料仓库":
                                (inDepotDisplay.warehouseType == 1?"研发物料仓库" :
                                inDepotDisplay.warehouseType == 2?"外协仓库":'售后仓库')}}
                            </td>
                        </tr>
                        <tr>
                            <td width="100px" style="text-align: left;padding-top: 10px">入库时间:</td>
                            <td style="text-align: left;padding-top: 10px">{{changeDateFormat(inDepotDisplay.inTime)}}
                            </td>
                            <td width="100px" style="text-align: left;padding-top: 10px">入库时间:</td>
                            <td style="text-align: left;padding-top: 10px">{{inDepotDisplay.isInning ==
                                0?'未入库':'已入库'}}
                            </td>
                        </tr>
                        <tr>
                            <td width="100px" style="text-align: left;padding-top: 10px">备注:</td>
                            <td style="text-align: left;padding-top: 10px" colspan="5">{{inDepotDisplay.remark}}</td>
                        </tr>
                        <tr>
                            <td colspan="6" style="padding-top: 10px">
                                <el-table
                                        :data="inDepotDisplay.list"
                                        border
                                        style="width: 100%;margin-bottom: 10px"
                                        max-height="340"
                                        :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                        :cell-style="{padding:'0',height: lineHeight}">
                                    <el-table-column prop="materialCode" label="物料编码" align="center"
                                                     show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="materialName" label="产品名称" align="center"
                                                     show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="model" label="型号" align="center"
                                                     show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="inCount" label="入库数量" align="center"
                                                     show-overflow-tooltip></el-table-column>
                                </el-table>
                            </td>
                        </tr>

                    </table>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="infoDialogVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="export">
            <el-dialog
                title="入库汇总表"
                :visible.sync="exportDialogVisible"
                width="80%"
                :close-on-click-modal="false"
                center
            >
                <div class="dialog-time">
                    <el-date-picker
                            v-model="startDate"
                            type="date"
                            size="small"
                            :clearable="false"
                            placeholder="选择开始时间"
                            value-format="yyyy-MM-dd">
                    </el-date-picker>
                    至
                    <el-date-picker
                            v-model="endDate"
                            type="date"
                            placeholder="选择结束时间"
                            size="small"
                            :clearable="false"
                            value-format="yyyy-MM-dd">
                    </el-date-picker>
                    <el-button type="primary" size="mini" style="margin-left:15px" @click="dialogFilter">筛 选</el-button
                >
                </div>
                <el-table
                    :data="excelTableData"
                    max-height="400px"
                    size="small"
                    row-class-name="row"
                    cell-class-name="column"
                    :highlight-current-row="true"
                    fit
                >
                    <el-table-column
                    v-for="(item, index) in jsonFields"
                    :key="index"
                    :prop="item.prop"
                    :label="item.label"
                    show-overflow-tooltip
                    >
                    </el-table-column>
                </el-table>
                <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
                <download-excel
                        style="width: 80px;margin: 0px auto;margin-top: 10px;"
                        :data="json_data"
                        :fields="json_fields"
                        name="入库汇总表"
                        >
                    <el-button
                        type="primary"
                        size="small"
                        @click="exportDialogVisible = false"
                        >导出数据
                    </el-button>
                </download-excel>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import dateFormat from '../../assets/js/dateFormat'
    export default {
        data() {
            return {
                excelTableData:[],
                startDate:'',
                endDate:'',
                jsonFields: [
                    { label: "入库单编号", prop: "inNum" },
                    { label: "入库类型", prop: "inType" },
                    { label: "入库仓库", prop: "warehouseType" },
                    { label: "物料名称", prop: "materialName" },
                    { label: "型号", prop: "model" },
                    { label: "单位", prop: "unitName" },
                    { label: "数量", prop: "inCount" },
                    { label: "单价(元)", prop: "buyPrice" },
                    { label: "金额合计(元)", prop: "buySum" },
                    { label: "操作员", prop: "updateUserName" },
                ],
                json_fields: {},
                json_data: [],
                exportDialogVisible: false,
                pageList: [15, 20, 30, 50],
                tableHeight: '',
                tableData: [],
                option: {
                    pageSize: 15,
                    pageIndex: 11,
                },
                totalPage: 10,
                editDialogVisible: false,
                isDisabled: false,
                infoDialogVisible: false,
                isLoad: false,
                lineHeight: '',
                editTitle: '',
                showTitle: '',
                searchItem: {
                    inNum: '',
                    warehouseType: '',
                    endDate: '',
                    startDate: '',
                    pageSize: 15,
                    pageIndex: 1,
                },
                depotTypes: [
                    {
                        value: 0,
                        label: '主物料仓库入库'
                    },
                    {
                        value: 1,
                        label: '研发仓库入库'
                    },
                    {
                        value: 2,
                        label: '外协物料入库'
                    },
                    {
                        value: 3,
                        label: '售后入库'
                    }
                ],
                depotList: [
                    {
                        value: 0,
                        label: '主物料仓库'
                    },
                    {
                        value: 1,
                        label: '研发物料仓库'
                    },
                    {
                        value: 2,
                        label: '外协仓库'
                    },
                    {
                        value: 3,
                        label: '售后仓库'
                    }
                ],
                inDepotForm: {
                    id: null,
                    inType: '',
                    warehouseType: '',
                    buyId: '',
                    remark: '',
                    afterSalesId: '',
                    mainInDetailStr: ''
                },
                inDepotDisplay: {
                    inNum: null,
                    inType: '',
                    isInning: '',
                    inTime: '',
                    remark: '',
                    warehouseType: '',
                    list: []
                },
                inDepotRules: {
                    inType: [{required: true, message: '请选择入库类型', trigger: 'blur'}],
                    warehouseType: [{required: true, message: '请选择入库仓库', trigger: 'blur'}]
                },
                tableList: [],
                checkList: [],
                purchaseList: [],
                afterList: [],
                multipleSelection: [],
                materialList: [],
                materialListStr: '',
                orderNum: '',
                labelName: '采购订单',
                orderName: '请选择采购订单',
                type: 0
            };
        },
        methods: {
            //导出excel
            exportExcel() {
                this.exportDialogVisible = true;
                var day1 = new Date();
                day1.setDate(day1.getDate() + 1);
                this.startDate = dateFormat("YYYY-mm-DD", new Date())
                this.endDate = dateFormat("YYYY-mm-dd ", day1)
                this.dialogFilter()
            },
            //导出excel的筛选
            dialogFilter(){
                this.$api.inStorageDetailPrint({startDate:this.startDate,endDate:this.endDate}).then(res=>{
                    if(res.code == 200){
                        res.data.forEach(item => {
                            item.buyPrice = item.inCount==0?0:(item.buySum/item.inCount/100).toFixed(2)
                            item.buySum = (item.buySum/100).toFixed(2)
                            switch(item.inType){
                                case 0:
                                    item.inType = '主物料仓库入库';
                                    break;
                                case 1:
                                    item.inType = '研发入库';
                                    break;
                                case 2:
                                    item.inType = '外协物料入库';
                                    break;
                                case 3:
                                    item.inType = '售后入库';
                                    break;
                                default:
                                    item.inType = '未找到该类型';
                            }
                            switch(item.warehouseType){
                                case 0:
                                    item.warehouseType = '主物料仓库';
                                    break;
                                case 1:
                                    item.warehouseType = '研发物料仓库';
                                    break;
                                case 2:
                                    item.warehouseType = '外协仓库';
                                    break;
                                case 3:
                                    item.warehouseType = '售后仓库';
                                    break;
                                default:
                                    item.warehouseType = '未找到该类型';
                            }
                        });
                        this.excelTableData = res.data
                        this.json_data = res.data
                    }
                })
            },
            pageChange(option) {
                if(option != "refresh"){
                    this.option.pageIndex = option;
                    this.searchItem.pageIndex = option;
                }
                this.$api.pageDepotIn(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.numberOfElements;
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.$api.pageDepotIn(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.numberOfElements;
                    }
                })
            },
            reset() {
                this.searchItem = {
                    inNum: '',
                    warehouseType: '',
                    endDate: '',
                    startDate: '',
                    pageSize: 15,
                    pageIndex: 1,
                }
                this.pageChange(1);
            },
            searchBtn() {
                this.pageChange(1);
            },
            dateFormat(row, column) {
                let data = row[column.property]
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date
            },
            changeDateFormat(date) {
                if (date == null || date == '' || date == undefined) {
                    return '';
                } else {
                    let dt = new Date(date)
                    var year = dt.getFullYear();
                    var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                    var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                    return year + '-' + month + '-' + date
                }
            },
            addObject() {
                this.afterList = [];
                this.purchaseList = [];
                this.editDialogVisible = true;
                this.isLoad = false;
                this.orderNum = ''
                this.type = 0
                this.isDisabled = false;
                this.editTitle = '新增入库信息'
                this.inDepotForm = {
                    id: null,
                    inType: null,
                    afterSalesId: null,
                    warehouseType: null,
                    buyId: '',
                    mainInDetailStr: '',
                }
                this.tableList = [];
                this.$api.getBuyList().then(res => {
                    if (res.code == 200) {
                        this.purchaseList = res.data
                    }
                })
                this.$api.getAfterList().then(res => {
                    if (res.code == 200) {
                        var newArr = res.data.filter(item=>{
                            return item.serviceType == 4 || item.serviceType == 5 || item.serviceType == 6
                        })
                        this.afterList = newArr
                    }
                })
            },
            cancel(formName) {
                this.$refs[formName].resetFields();
                this.editDialogVisible = false;
            },
            submit(formName) {
                this.isLoad = true;
                var checkedStr = ''
                if (this.inDepotForm.inType == 3 && !this.inDepotForm.afterSalesId) {
                    this.$message.error('售后入库的售后订单不能为空')
                    this.isLoad = false;
                    return;
                }
                for (const item of this.tableList) {
                    if (item.inCount == 0) {
                        item.inCount = '0';
                    }
                    if (item.checked == true) {
                        if (item.inCount == "" || item.inCount == undefined || item.inCount == null) {
                            this.$message.error('所选物料的数量不能为空')
                            this.isLoad = false;
                            return;
                        } else if (item.count != "" && item.count != undefined && item.count != null) {
                            
                            if (item.count * 1 < item.inCount * 1) {
                                this.$message.error('所选物料的入库数量不能大于于采购数量')
                                this.isLoad = false;
                                return;
                            }
                        }
                        if (item.checked == true) {
                            if (item.inCount > 0) {
                                if (checkedStr == '') {
                                    checkedStr = item.id + ',' + item.inCount
                                } else {
                                    checkedStr = checkedStr + "|" + item.id + ',' + item.inCount
                                }
                            }
                        }
                    } else {
                        continue;
                    }
                }
                if (checkedStr == '') {
                    this.$message.error('入库的物料不能为空')
                    this.isLoad = false;
                    return;
                } else {
                    this.inDepotForm.mainInDetailStr = checkedStr;
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.type == 0) {
                            this.$api.addDepotIn(this.inDepotForm).then(res => {
                                if (res.code == 200) {
                                    this.pageChange("refresh");
                                    this.$message.success(res.message)
                                    this.editDialogVisible = false
                                    this.isLoad = false;
                                } else {
                                    this.$message.error(res.message)
                                    this.isLoad = false;
                                }
                            })
                        } else {
                            this.$api.updateDepotIn(this.inDepotForm).then(res => {
                                if (res.code == 200) {
                                    this.pageChange("refresh");
                                    this.$message.success(res.message)
                                    this.editDialogVisible = false
                                    this.isLoad = false;
                                } else {
                                    this.$message.error(res.message)
                                    this.isLoad = false;
                                }
                            })
                        }
                    } else {
                        this.isLoad = false;
                        return false;
                    }
                });
            },
            toggleSelection(rows) {
                if (rows) {
                    this.$nextTick(function () {
                        for (var row of rows) {
                            this.$refs.multipleTable.toggleRowSelection(row, true)
                        }
                    })
                }
            },
            handleSelectionChange(val) {
                var flag = false;
                if (this.uniqueId(this.multipleSelection).length < val.length) {
                    flag = true;
                }
                if (flag) {
                    for (var item of this.tableList) {
                        if (val.length > 0) {
                            for (const itemObj of val) {
                                if (item.id == itemObj.id) {
                                    if (item.checked) {
                                        continue;
                                    } else {
                                        item.checked = true;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    for (var item of this.tableList) {
                        item.checked = false;
                    }
                    for (var item of this.tableList) {
                        if (val.length > 0) {
                            for (const itemObj of val) {
                                if (item.id == itemObj.id) {
                                    if (item.checked) {
                                        continue;
                                    } else {
                                        item.checked = true;
                                    }
                                }
                            }
                        }
                    }
                }
                this.multipleSelection = val
            },
            uniqueId(arr) {
                var newArr = [];
                var obj = [];
                for (var i = 0; i < arr.length; i++) {
                    if (!obj[arr[i].id]) {
                        newArr.push(arr[i]);
                        obj[arr[i].id] = true;
                    }
                }
                return newArr;
            },
            checkType(val) {
                if (this.inDepotForm.buyId != '' && this.inDepotForm.buyId != undefined && this.inDepotForm.buyId != null) {
                    return;
                } else {
                    if (val == 0) {
                        this.inDepotForm.warehouseType = 0;
                        this.labelName = '采购订单'
                        this.orderName = '请选择采购订单'
                        this.getMaterialList(this.inDepotForm.warehouseType);
                    } else if (val == 1) {
                        this.inDepotForm.warehouseType = 1;
                        this.labelName = '采购订单'
                        this.orderName = '请选择采购订单'
                        this.getMaterialList(this.inDepotForm.warehouseType);
                    } else if (val == 2) {
                        this.inDepotForm.warehouseType = 2;
                        this.labelName = '采购订单'
                        this.orderName = '请选择采购订单'
                        this.getMaterialList(this.inDepotForm.warehouseType);
                    } else {
                        this.inDepotForm.warehouseType = 3;
                        this.labelName = '售后订单'
                        this.orderName = '请选择售后订单'
                        this.getMaterialList(this.inDepotForm.warehouseType);
                    }
                }
            },
            getMaterialList(val) {
                this.$api.getMaterialList({warehouseType: val}).then(res => {
                    if (res.code == 200) {
                        for (var item of res.data) {
                            item.checked = false;
                        }
                        this.materialList = res.data;
                        this.materialListStr = JSON.stringify(res.data)
                    }
                })
            },
            getOrderDetail(val) {
                this.isDisabled = true;
                for (var item of this.purchaseList) {
                    if (val == item.id) {
                        this.inDepotForm.warehouseType = item.warehouseType;
                    }
                }
                this.inDepotForm.buyId = val;
                this.$api.getBuyDetailList({buyId: val}).then(res => {
                    if (res.code = 200) {
                        for (var item of res.data) {
                            item.checked = true;
                            item.outCount = item.outCoun - res.data.inCount;
                        }
                        this.tableList = res.data;
                    }
                })
            },
            getAfterDetail(val) {
                this.isDisabled = true;
                this.inDepotForm.afterSalesId = val;
                this.$api.afterSalesOutListInfoById({id: val}).then(res => {
                    if (res.code = 200) {
                        var newArr = []
                        if (res.data) {
                            for (var item of res.data) {
                                for (const itemObj of JSON.parse(this.materialListStr)) {
                                    itemObj.checked = false;
                                    if (itemObj.id == item.materialId) {
                                        itemObj.outCount = item.outCount;
                                        itemObj.checked = true;
                                    }
                                    newArr.push(itemObj)
                                }
                            }
                        }
                        else{                            
                                for (const itemObj of JSON.parse(this.materialListStr)) {
                                    itemObj.checked = false;
                                    newArr.push(itemObj)
                                }
                        }
                        var materialArr = []
                        for (var item of newArr) {
                            if (item.checked){
                                materialArr.push(item)
                            }
                        }
                        for (const item of newArr) {
                            materialArr.push(item)
                        }
                        this.tableList = this.uniqueId(materialArr)
                        var checkArr = []
                        for (var item of newArr) {
                            if (item.checked){
                                checkArr.push(item)
                            }
                        }
                        this.toggleSelection(checkArr)
                    }
                })
            },
            editInDepot(id) {
                this.$api.getBuyList().then(res => {
                    if (res.code == 200) {
                        this.purchaseList = res.data
                    }
                })
                this.$api.getAfterList().then(res => {
                    if (res.code == 200) {
                        this.afterList = res.data
                    }
                })
                this.type = 1;
                this.$api.getInDepotDetail({id: id}).then(res => {
                    if (res.code == 200) {
                        this.editTitle = '编辑《' + res.data.inNum + '》入库信息';
                        this.editDialogVisible = true;
                        this.inDepotForm = {
                            id: res.data.id,
                            inType: res.data.inType,
                            buyId: res.data.buyId,
                            afterSalesId: res.data.afterSalesId,
                            warehouseType: res.data.warehouseType,
                            remark: res.data.remark,
                        }
                        if (res.data.inType == 3) {
                            this.isDisabled = true;
                            this.checkType(res.data.inType)
                        } else {
                            this.checkType(res.data.inType)
                        }
                        this.toggleSelection(res.data.materialList)
                        this.tableList = res.data.materialList;
                    }
                })
            },
            submitInDepot(id) {
                this.$confirm('你确定要入库该入库单信息吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.sureInDepot({id: id}).then(res => {
                        if (res.code == 200) {
                            this.pageChange("refresh");
                            this.$message.success(res.message)
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                }).catch(() => {
                })
            },
            showInDepot(id) {
                this.$api.getInDepotDetail({id: id}).then(res => {
                    this.infoDialogVisible = true;
                    if (res.code == 200) {
                        this.inDepotDisplay = {
                            inType: res.data.inType,
                            warehouseType: res.data.warehouseType,
                            inNum: res.data.inNum,
                            remark: res.data.remark,
                            inTime: res.data.inTime,
                            isInning: res.data.isInning,
                            list: res.data.materialList
                        }
                        this.showTitle = '《' + res.data.inNum + '》入库信息';
                    }
                })
            },
        },
        created() {
            this.jsonFields.map((item, i) => {
                this.json_fields[item.label] = item.prop;
            });
            this.pageChange(1)
            this.tableHeight = (window.innerHeight) * 0.76
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    };
</script>
<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .iconbianji {
        color: #20a0ff;
    }

    .icontongguo {
        color: green;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 6%;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    td {
        text-align: right;
    }
</style>